@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#loginInput {
  -webkit-text-fill-color: #474747;
  opacity: 1; /* required on iOS */
}

code {
  font-family: "Roboto", sans-serif;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color:orange !important;
}
