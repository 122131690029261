.react-joyride__tooltip {
  display: block !important;
  z-index: 9999 !important;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-2 {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .mobile-border-radius {
    border-radius: 12px !important;
  }
}

.jess_title {
  /* width: 524px; */
  height: 90px;
  left: 114px;
  top: 194px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;

  letter-spacing: -0.005em;

  color: #101010;
}

.sub_jess_title {
  position: absolute;
  padding-top: 40px;
  /* width: 524px; */
  height: 100px;
}

.get_access_div {
  /* width: 482px; */
  height: 53px;
  background: #f2edff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 21px;

  /* or 133% */

  color: #a07bec;
}

.sub_jess_title_heading {
  color: #101010;
  text-align: left;
  font-size: 18px;
  /* width: 500px; */
  line-height: 24px;
  font-weight: bold;
  font-weight: 400;
}

.jess_icon {
  position: absolute;
  width: 167px;
  height: 42px;
  top: 21px;
  /* left: 35px; */
}

.teepee_icon_container {
  display: block;
  margin-top: 20px;

  margin-right: auto;
  width: 50%;
}

.login_button_about_us {
  position: absolute;

  top: 21px;
  /* left: 35px; */
}

.teepee_icon_about_us {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}

.jess_footer {
  position: absolute;
  /* width: 75%; */
  font-size: 14px;
  /* height: 42px; */
  bottom: 0;
  /* left: 35px; */
  width: 100%;
}

.jess_footer_sm {
  float: left;
  width: 100%;
  font-size: 14px;
  height: 42px;
  bottom: 21px;
  /* left: 35px; */
}

.sign_in_heading {
  padding-bottom: 310px;
  /* width: 323px; */
  height: 51px;
  left: 834px;
  top: 194px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;

  color: #101010;
}

.sub_sign_in_heading {
  /* width: 234.27px; */
  height: 18px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #101010;
  position: absolute;
  margin-bottom: 180px;
}

.username_textfield {
  position: absolute;
  /* width: 570px; */
}

.password_textfield {
  position: absolute;
  /* width: 570px; */
  margin-top: 200px;
}

.button_style {
  position: absolute;
  /* width: 570px; */
  margin-top: 500px;
  font-size: 20px;
  font-weight: normal;
  font-weight: 450px;
}

.dashboard_title {
  padding-left: 30px;
}

.create_quiz_button {
  margin-top: 40px;
  text-align: center;
  color: #6750a4;
  background-color: #ece5f2;
  height: 36px;
  width: 280px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-small {
  font-size: 0.75em;
}

.create_quiz_button_dashboard {
  margin-top: 0px;
  text-align: center;
  color: #6750a4;
  background-color: #ece5f2;
  height: 36px;
  width: 280px;
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}
.create_quiz_button_dashboard-new {
  margin-top: 0px;
  text-align: center;
  color: var(--m-3-sys-light-on-primary, #fff);
  background-color: var(--m-3-sys-light-primary, #6750a4);
  height: 36px;
  width: 280px;
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
}
.create_quiz_button_dashboard-new2 {
  margin-top: 0px;
  text-align: center;
  background-color: var(--m-3-sys-light-on-primary, #fff);
  color: var(--m-3-sys-light-primary, #6750a4);
  height: 36px;
  width: 280px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid var(--m-3-sys-light-primary, #6750a4); /* Border color same as text color */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  font-size: 2rem;
}

.create_quiz_div {
  margin-top: 20px;
  display: flex;
  margin-left: 60px;
  height: 150px;
  width: 300px;
  background-color: #ece5f2;
  justify-content: center;
  align-items: center;
}

.create_quiz_label {
  font-size: 18px;
  font-weight: 300;
}

.create_quiz_label:after {
  content: "*";
  color: red;
}
.create_quiz_label-1 {
  font-size: 18px;
  font-weight: 300;
}
.normal_label {
  font-size: 18px;
  font-weight: 300;
}

.character-length-label {
  display: flex;
  font-size: 14px;
  /* font-weight: 100; */
  /* padding-left: 450px; */
}

.teepee-input {
  font-size: 20px;
  color: #474747;
  height: 56px;
  width: 100%;
  background-color: white;
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #79747e;
  padding-left: 1rem;
  padding-right: 1rem;
}
.teepee-input-2 {
  font-size: 20px;
  color: #474747;
  height: 40px;
  width: 100%;
  background-color: white;
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #79747e;
  padding-left: 1rem;
  padding-right: 1rem;
}
.teepee-textarea {
  font-size: 20px;
  color: #474747;
  height: 64px;
  /* width: 100%; */
  min-height: 200px;
  background-color: white;
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #79747e;
  padding-left: 5px;
  padding-right: 5px;
}

.teepee-prompt-textarea {
  font-size: 16px;
  color: #474747;
  height: 64px;
  width: 100%;
  min-height: 200px;
  background-color: white;
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #79747e;
  padding-left: 5px;
  padding-right: 5px;
}

.teepee-question-textarea {
  font-size: 16px;
  color: #474747;
  height: 34px;
  width: 100%;
  min-height: 100px;
  background-color: white;
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #79747e;
  padding-left: 5px;
  padding-right: 5px;
}

.teepee-answer-textarea {
  font-size: 16px;
  color: #474747;
  height: 34px;
  width: 100%;
  min-height: 100px;
  background-color: white;
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #79747e;
  padding-left: 5px;
  padding-right: 5px;
}

.teepee-select {
  font-size: 20px;
  color: #474747;
  height: 50px;
  width: 100%;
  background-color: white;
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #79747e;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  margin-bottom: 0.5rem;
}

.teepee-button {
  color: #ffffff;
  font-size: 18px;
  float: right;
  background: #6750a4;
  border-radius: 5px /* 6px */;
  font-weight: normal;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
}

.create-quiz-button {
  /* display: flex;
  margin-left: 360px;
  margin-top: 50px;
  border-radius: 10px !important; */
}

.subtopic_checkbox {
  display: flex;

  font-size: 2rem;
  /* margin-top: 30px; */
}

.form-div {
  padding-top: 0px;
  display: flex;
  /* margin-left: 330px; */
  font-size: 2rem;
  justify-content: center;
  margin-bottom: 9.25rem;
}

.form-div2 {
  padding-top: 0px;
  display: flex;
  /* margin-left: 330px; */
  /* font-size: 2rem; */
  justify-content: center;
}

.create-quiz-button-icon {
  margin-top: 5px;
  margin-right: 8px;
  vertical-align: middle;
  color: #664dac;
  font-weight: 500;
  height: 12px;
  width: 12px;
}
p {
  margin-bottom: 5px !important;
}

.dashboard-label {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #6750a4;
  font-weight: 600;
}

.disabled-label {
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: black;
  font-weight: 500;
}

.teepee-divider {
  border-top: 4px solid #ece5f2;
  margin: 5px 0px;
  border-radius: 4px;
}

.teepee-back-button {
  color: #6750a4;
  font-size: 18px;
  float: left;
  background: white;
  border-radius: 5px /* 6px */;
  font-weight: normal;
  padding-top: 0.3rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  border-color: #6750a4;
  border-top: 2px solid #664dac;
  border-bottom: 2px solid #664dac;
  border-left: 2px solid #664dac;
  border-right: 2px solid #664dac;
}
.teepee-back-button-with-grey-border {
  color: #6750a4;
  font-size: 18px;
  float: left;
  background: white;
  border-radius: 5px /* 6px */;
  font-weight: normal;
  padding-top: 0.3rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  border-color: #DDDCDF;
  border-top: 1px solid #DDDCDF;
  border-bottom: 1px solid #DDDCDF;
  border-left: 1px solid #DDDCDF;
  border-right: 1px solid #DDDCDF;

}

.student-name-label {
  font-family: Roboto;
  margin-right: 50px !important;
  font-size: 16px;
  text-align: left;
}

.email-name-label {
  font-family: Roboto;
  margin-right: 50px !important;
  font-size: 16px;
  text-align: left;
}

.quiz-label {
  font-family: Roboto;
  margin-right: 50px !important;
  font-size: 16px;
  text-align: center;
}

.student-name-label:after {
  content: "*";
  color: red;
}

hr.solid {
  border-top: 1px solid black;
}

.my-quiz-div {
  padding-top: 1.875rem;
  margin-right: 20px;
}

.no-quiz-label {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 300;
}

/* .selectionDiv {
  float: left;
  display: flex;
  background: aquamarine;
  width: 100%;
  justify-content: center;
  align-items: center;
} */

.create_quiz_div-main {
  margin-top: 20px;
  display: flex;
  height: 150px;
  width: 300px;
  background-color: #ece5f2;
  justify-content: center;
  align-items: center;
}
.create_quiz_div-main-new {
  margin-top: 20px;
  display: flex;
  width: 300px;
  background-color: var(--m-3-sys-light-primary, #6750a4);
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.create-quiz-button-icon-main {
  position: fixed;
  margin-left: 65px;
  vertical-align: middle;
  float: left;
  color: #664dac;
  font-weight: 500;
  height: 40px;
  width: 70px;
}

.quiz-div {
  /* width: 360px; */
  top: 405px;
  left: 440px;
  border-radius: 4px;
  border: 1px solid #f3f1f8;
  /* margin-right: 20px; */
  padding-bottom: 14px;
}

.my-quizzes {
  padding-top: 30px;
  margin-left: 60px;
}

.inner-my-quizzes {
  /* width: 360px; */
  height: 63px;
  top: 406px;
  left: 440px;
  background-color: #f7f2f9;

  padding-left: 20px;
  padding-top: 12px;
}

.dashboard-my-quizzes-name {
  color: #1c1b1f;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.dashboard-my-quizzes-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.dashboard-my-quizzes-info {
  padding-top: 0px;
  padding-left: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
}

.dashboard-my-quizzes-subject {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1b1f;
}

.dashboard-my-quizzes-topic {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #49454f;
}

.my-quizzes-card_button {
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  padding-top: 10px;
}

.dashboard_buttons {
  display: flex;
  justify-content: space-between;
}

.my-quizzes-card-buttons {
  width: 110px;
  height: 40px;
  top: 560px;
  left: 545px;
  border-radius: 4px;
}

.selected-quiz-option-button {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  top: 560px;
  left: 545px;
  border-radius: 4px;
  border: 1px solid #79747e;
  color: #79747e;
}

.my-quizzes-card-view-quiz-button {
  margin-right: 10px;
  border: 0.5px solid #6750a4;
  color: #6750a4;
}

.create-folder-button {
  margin-right: 20px;
  border: 1px solid #79747e;
  color: #79747e;

  width: 110px;
  height: 40px;
  top: 560px;
  left: 545px;
  border-radius: 4px;
}

.my-quizzes-card-view-result-button {
  background-color: #6750a4;
  color: white;
}

.view-result-quiz-name {
  /* width: 340px; */
  height: 24px;
  left: 444px;
  top: 90px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #4b4a4b;
}

.view-quiz-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

table {
  font-family: "Roboto";
}

td {
  text-align: start;
  margin-left: 214px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #4b4a4b;
}

.tr {
  border-bottom: 1px solid #e2e2e2;
  transform: rotate(0.05deg);
  height: 70px;
}

th {
  height: 85px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */
  letter-spacing: 0.15px;

  /* color: #4b4a4b; */
}

.student-info {
  height: 31px;
  left: 447px;
  top: 153px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 1000;
  font-size: 20px;
  line-height: 20px;

  /* or 100% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  /* M3/sys/light/on-surface-variant */
  color: #49454f;
}

.quiz-info-light {
  height: 31px;
  left: 447px;
  top: 153px;

  font-family: "Roboto";

  font-weight: 200;
  font-size: 16px;
  line-height: 20px;

  /* or 100% */
  display: flex;
  /* align-items: center; */
  letter-spacing: 0.1px;

  /* M3/sys/light/on-surface-variant */
  color: #000000;
}

.question {
  /* M3/label/large */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* line-height: 20px; */

  /* or 143% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  /* M3/sys/light/on-surface-variant */
  color: #49454f;
  display: inline-block;
}

.question-text {
  /* M3/label/large */
  font-family: "Roboto";
  font-style: normal;

  font-size: 16px;
  /* line-height: 20px; */

  /* or 143% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  /* M3/sys/light/on-surface-variant */
  color: #49454f;
}

.quiz-review {
  width: 100%;
  /* height: 29.54px; */
  padding: 10px;
  left: 444px;
  top: 651.69px;

  background: #f7f2f9;
  border-radius: 5px;
  display: inline-block;
}

.performace-label {
  height: 24px;
  left: 835px;
  top: 235px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;

  /* identical to box height, or 240% */
  letter-spacing: 0.15px;

  color: #4b4a4b;
}

.teepee-label {
  /* width:40%; */
  height: 31px;
  left: 447px;
  top: 153px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  /* or 100% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  /* M3/sys/light/on-surface-variant */
  color: #49454f;
}

.question-score {
  width: auto;
  /* height: 29.54px; */
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  left: 444px;
  top: 651.69px;
  background: #f7f2f9;
  border-radius: 5px;
  display: inline-block;
}

.teepee-sidebar-icon {
  width: 200px;
}

.email-label {
  /* width: 56.71px; */
  height: 18px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #353846;
  margin-bottom: 10px;
  /* margin-left: 20px; */
}

.early_access_button {
  width: 180px;
  height: 45px;
  background: #101010;
  border-radius: 4px;
}

.early_access__dialog_close_button {
  width: 180px;
  height: 45px;
  background: #101010;
  color: white;
  border-radius: 4px;
}

.main-div-login {
  position: fixed;
  left: 150px;
  top: 350px;
}

.teepee-heading {
  color: #1d192b;
  font-family: Segoe UI;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 112.5% */
  letter-spacing: 2.16px;
}

.teepee-sub-heading {
  color: #49454f;
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  /* 133.333% */
  letter-spacing: -0.16px;
}

.teepee-disclaimer {
  margin-top: 34px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #101010;
}

.teepee-get-early-access-input {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 21px;
  background-color: #ece5f2;
  color: #6750a4;
}

.teepee-get-early-access-button-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.sign-in-label {
  color: #1d192b;
  text-align: center;
  font-family: Segoe UI;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
}

.sign-in-sub-label {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #101010;
}

.textfield-text {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #7e7f86;
}

.sign-in-button {
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  color: #ffffff;
}

.sign-in-text {
  color: #6750a4;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  text-decoration-line: underline;
}

.create-new-account-button {
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  color: #000000;
}

#early_access_email_input::placeholder {
  color: #6750a4;
  opacity: 1;
}

.disable-button {
  color: #6750a4 !important;
  background: #f0f0f0 !important;
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* For demonstration purposes only: */
body {
  font-size: 3.5em;
}

h1 {
  font-size: 0.5em;
}

.text-with-dots {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-with-dots-without-width {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.answer-paragraph {
  font-family: "Roboto";
  word-wrap: break-word;
  white-space: break-spaces;
}

.words-limit-reached {
  color: red;
}

#signup-form .MuiInputBase-input {
  margin-top: 5px !important;
  border-color: #8b8b90;
  border: 1px solid !important;
  border-radius: 0.375rem !important;
  height: 45px !important;
  padding: 0px !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

#signup-form .MuiInput-underline:after {
  border-bottom: none !important;
}

#signup-form .MuiInput-underline:before {
  border-bottom: none !important;
}

.words-limit-reached-input-field {
  border: 1px solid;
  border-color: red;
  background: #fdf5f5;
  background-color: #fdf5f5;
}

.like-dislike-container {
  display: flex;
  align-items: right;
  justify-content: right;
}

.like-dislike-btn-container {
  display: flex;
  flex-direction: "row";
  align-items: center;

  border-radius: 12px;
}

.like-dislike-btn {
  border: none;
  color: #fff;
  font-size: 0.8rem;
  display: flex;
  align-items: right;
  gap: 6px;
  background-color: #bdc0c5;
  border-radius: 4px;
  padding: 6px 15px;
  margin-left: 10px;
}

.material-icons.like-active {
  color: #9ca2aa;
  cursor: pointer;
}

.material-icons.dislike-active {
  color: #9ca2aa;
  cursor: pointer;
}

.material-icons {
  font-size: 20px;
  color: #9ca2aa;
  cursor: pointer;
}

.material-symbols-rounded {
  font-size: 20px;
  color: #9ca2aa;
  cursor: pointer;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

#profile-dropdown {
  background-color: transparent;
  height: 20px;
  border: none;
}

.sticky-div {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
}

hr {
  opacity: 1;
}

.early-access-spinner {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  float: left !important;
  display: flex !important;
}

.new-line {
  white-space: pre-line;
}

.css-1fjvggn-MuiPaper-root-MuiAccordion-root:first-of-type {
  min-width: -moz-fit-content;
}

.subtopic_name {
  display: inline-block;
  width: 280px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.teepee_dialogue {
  width: 768px;
  height: 562px;
  flex-shrink: 0;
}

.join-classroom-button {
  color: var(--m-3-sys-light-primary, #6750a4);
  /* M3/label/large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.1px;
}

.create-class-icon {
  margin-top: 4px;
  vertical-align: middle;
  color:#664dac; /* Default color */
  font-weight: 500;
  height: 12px;
  width: 12px;
  /* transition: color 0.1s ease; Smooth transition effect */
}

.create-class-icon:hover {
  color: #664dac; /* Color on hover */
}

.create_new_classroom_dialogue_title {
  color: var(--m-3-sys-light-on-surface, #1c1b1f);
  text-align: center;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  /* 135.294% */
  letter-spacing: 0.15px;
}

.create_your_class_label {
  color: var(--m-3-sys-light-on-surface, #1c1b1f);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 116.667% */
  letter-spacing: 0.15px;
  margin-bottom: 49px;
}

.class_dialogue_label {
  color: #49454f;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;
}

.class_name_hint_label {
  color: #95a8b2;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.class_name_input {
  outline: none;
  width: 647px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #79747e;
}

.class_name_dialogue_copy_button {
  width: 70px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #49454f;
  color: #6750a4;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
}

.classroom_name_div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.classroom_name {
  color: #49454f;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 127.273% */
  letter-spacing: 0.15px;
}

.quiz-item {
  width: 100%;
  /* Set a width for the parent div */
  overflow: hidden;
  /* Hide overflowing content */
  white-space: nowrap;
  /* Prevent text from wrapping */
  text-overflow: ellipsis;
  /* Add ellipsis (...) to indicate truncation */
}

.classroom_details_label {
  color: #1d192b;
  /* font-family: Roboto; */
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teacher_navigation_text {
  color: var(--link-color, #a07bec);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}

.table_text {
  color: var(--primary-color, #6750a4);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.enter_classcode {
  color: #1d192b;
  text-align: center;
  font-family: Segoe UI;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
}

.code_instruction {
  color: #49454f;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.code_div {
  width: 284px;
  height: 64px;
  border-radius: 4px;
  border: 1px solid rgba(121, 116, 126, 0.5);
}

.already_account {
  color: var(--link-color, #a07bec);
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.classroom_heading {
  color: #1d192b;
  /* M3/display/medium */
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  /* 115.556% */
}

.class_name {
  color: #6750a4;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.1px;
}

.quiz_circle_student {
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.view_class {
  color: #6750a4;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  text-transform: lowercase;
  /* margin-left: auto; */
  margin-right: 20px;
}

.login-options {
  color: #49454f;
  text-align: center;
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.create-new-account-text {
  color: var(--link-color, #a07bec);
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  width: 100%;
}

.truncate {
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides any overflow beyond the container */
  text-overflow: ellipsis;
  /* Adds an ellipsis (...) to indicate truncated text */
  display: block;
  /* Ensures the ellipsis is displayed even if the text is one long word */
  max-width: 100%;
  /* Limits the width of the content to the container's width */
}

.max-w-xs {
  max-width: 150px;
  /* Adjust the value as needed */
}

.leave-classroom {
  color: #1d192b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.014px;
}

.dialogue-button-text {
  color: #6750a4;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.1px;
}

.tracking_table {
  border: 1px solid rgba(73, 69, 79, 0.4);
}

.tracking_table > .spth {
  width: 50px !important;
  height: 85px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1d192b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tracking_table_td {
  color: #49454f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tracking_table_inside {
  border-left: 2px solid rgba(73, 69, 79, 0.4);
  border-top: 2px solid rgba(73, 69, 79, 0.4);
  border-bottom: 2px solid rgba(73, 69, 79, 0.4);
  border-right: 1.2px solid rgba(73, 69, 79, 0.4);
}

.student-score-table-label {
  color: #49454f;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
}

.feedback_text {
  font-size: 0.6rem;
  line-height: 1rem;
  /* color: rgba(197,197,210,var(--tw-text-opacity)); */
  color: gray;
}

.inside_classroom_quiz_info {
  color: #1d192b;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  /* 122.222% */
}

.inside_classroom_quiz_analysis_heading {
  color: var(--primary-color-prominent-headings, #1d192b);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  /* 128.571% */
}

.inside_classroom_quiz_analysis_subheading {
  color: var(--secondary-color, #49454f);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.topic_name_topic_analysis_card {
  color: var(--primary-color-prominent-headings, #1d192b);

  /* M3/title/medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;
}

.subheading_topic_analysis_card {
  color: #49454f;

  /* M3/body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.25px;
}

.percentage_in_topic_analysis_card {
  color: var(--primary-color-prominent-headings, #1d192b);
  font-family: Roboto;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  /* 115.556% */
  letter-spacing: 0.5px;
}

.progress_analytics_parent_heading {
  color: var(--secondary-color, #49454f);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 127.273% */
}

.progress_analytics_parent_sub_heading {
  color: var(--secondary-color, #49454f);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

#score button {
  display: none;
  position: relative;
  top: 0;
}

#score:hover button {
  display: block;
}

.edited_status {
  color: rgba(73, 69, 79, 0.7);
  font-family: Roboto;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
}

.styles-module_Editext__buttons_showButtonsOnHover__2Bfsd:hover .styles-module_Editext__buttons_container__2za5Q {
  display: block;
}

.styles-module_Editext__buttons_showButtonsOnHover__2Bfsd .styles-module_Editext__buttons_container__2za5Q {
  display: none;
}

.styles-module_Editext__view_container__3oSYx {
  align-items: start;
}

.styles-module_Editext__button__sxYQX {
  border-radius: 1px;
  outline: none;
  padding: 0px !important;
  min-width: 30px;
  height: 100%;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-width: 1px;
}
.sticky_dashboard_text {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.25px;
}
/* .css-ja5taz-MuiTooltip-tooltip{
  background-color: #000000 !important;
} */

#username::placeholder {
  color: #79747e;
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
#password::placeholder {
  color: #79747e;
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
#confirm_password::placeholder {
  color: #79747e;
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.classroom-dashboard-selected-tab {
  color: #1d192b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.014px;
}
.onboarding-subject-text {
  color: var(--m-3-sys-light-on-secondary-container, #1d192b);
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.skip {
  color: #6750a4;

  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.5px;
}
.teepee-disabled-button {
  color: var(--m-3-sys-light-on-surface, #1d1b20);
  font-size: 14px;
  float: right;
  background: var(--m-3-state-layers-light-on-surface-opacity-012, rgba(29, 27, 32, 0.12));
  border-radius: 5px;
  font-weight: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.new_subject_label {
  color: var(--m-3-sys-light-on-surface, #1c1b1f);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 128.571% */
}
.subject_name_hint_label {
  color: #1c1b1f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.welcome-teepee-text {
  color: #1d192b;
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.welcome-teepee-sub-text {
  color: #1d192b;
  font-family: Segoe UI;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
}
.downloadCSV-button {
  border-radius: 4px;
  border: 1px solid rgba(28, 27, 31, 0.12);
  color: #1c1b1f;
  text-align: right;
  padding: 10px 24px 10px 16px;
  font-family: Roboto;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.onboarding-heading {
  color: #1d192b;
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}
.onboarding-sub-heading1 {
  color: #49454f;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.onboarding-sub-heading2 {
  color: rgba(73, 69, 79, 0.84);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}
.first-class-button {
  padding: 15px 24px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #6750a4;
  background: #fefefe;
  color: #6750a4;

  text-align: center;
  /* M3/label/large */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.heading-create-class-tooltip {
  color: #49454f;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.sub-heading-create-class-tooltip {
  color: #49454f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.first-button-create-class-tooltip {
  color: #49454f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.view-all {
  color: #6750a4;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.class_name_input_create {
  outline: none;
  /* width: 647px; */
  height: 40px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #79747e;
}
.class_analytics_text_bold {
  color: #49454f;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.class_analytics_text_second {
  color: var(--secondary-color, #49454f);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.quiz-item {
  position: relative;
}
.spinner-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10; /* Ensures spinner is above other content */
}

.loading-spinner {
  border: 5px solid #f3f3f3; /* Light grey border */
  border-top: 5px solid #3498db; /* Blue color for the spinner */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.shimmer-wrapper {
  height: 50px; /* Adjust the height as needed */
  width: 100%; /* Adjust the width as needed */
  background: #f6f7f8;
  overflow: hidden;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: block;
  position: relative;
  animation: loading 1.5s infinite;
}

.classroom_heading1 {
  color: #1d192b;
  /* M3/display/medium */
  font-family: Roboto;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  /* 115.556% */
}
@media (max-width: 600px) {
  /* Adjust the max-width as needed for your target mobile view */
  .classroom_heading1 {
    color: #1d192b;
    /* M3/display/medium */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
  }
}

@keyframes loading {
  0% {
    left: -800px;
  }
  100% {
    left: 100%;
  }
}
.searching-icon {
  background-image: url("../src/images/search_24px.png");
  /* Add a search icon to input */
  background-position: 280px 13px;
  /* Position the search icon */
  background-repeat: no-repeat;
  /* Do not repeat the icon image */
  height: 45px;
  width: 320px;
  /* Increase font-size */

  /* Add some padding */

  /* Add a grey border */

  /* Add some space below the input */
}
.dateAnalytics .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  width: 2em !important;
}
.dropdown-toggle::after {
  display: none !important;
}
input[type="radio"] {
  accent-color: #6750a4;
}
.subscription-card-graident-type-1 {
  background: rgb(68, 164, 213);
  background: linear-gradient(90deg, rgba(68, 164, 213, 1) 0%, rgba(145, 127, 190, 1) 50%, rgba(227, 88, 166, 1) 100%);
}
.subscription-card-graident-type-2 {
  background: rgb(206, 116, 238);
  background: linear-gradient(90deg, rgba(206, 116, 238, 1) 0%, rgba(127, 127, 212, 1) 50%, rgba(47, 140, 181, 1) 100%);
}
.subscription-card-graident-type-3 {
  background: rgb(152, 55, 188);
  background: linear-gradient(90deg, rgba(152, 55, 188, 1) 0%, rgba(114, 104, 203, 1) 50%, rgba(69, 202, 234, 1) 100%);
}
.subscribed-cards-type-1 {
  background: rgb(49, 147, 183);
  background: linear-gradient(90deg, rgba(49, 147, 183, 1) 0%, rgba(59, 176, 208, 1) 100%);
}
.subscribed-cards-type-2 {
  background: rgb(219, 46, 46);
  background: linear-gradient(90deg, rgba(219, 46, 46, 1) 0%, rgba(246, 96, 96, 1) 100%);
}
.subscribed-cards-type-3 {
  background: rgb(49, 149, 176);
  background: linear-gradient(90deg, rgba(49, 149, 176, 1) 0%, rgba(65, 212, 151, 1) 100%);
}

.subscription-card-box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.rs-slider-handle:before {
  background-color: #6750a4 !important;
  /* background-color: var(--rs-slider-thumb-bg); */
  border: 2px solid #6750a4 !important;
  border-radius: 15px !important;
  content: "" !important;
  cursor: pointer !important;
  height: 15px !important;
  margin-left: -6px !important;
  position: absolute !important;
  -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out !important;
  transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out !important;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out !important;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out !important;
  width: 7px !important;
}
.css-7drnjp {
  background-color: #6750a4 !important;
  /* background-color: var(--rs-slider-thumb-bg); */
  border: 2px solid #6750a4 !important;
  border-radius: 15px !important;
  content: "" !important;
  cursor: pointer !important;
  height: 15px !important;
  /* margin-left: -6px !important; */
  position: absolute !important;
  -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out !important;
  transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out !important;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out !important;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out !important;
  width: 7px !important;
}
.css-1ejytfe {
  position: absolute !important;
  width: 4px !important;
  height: 4px !important;
  border-radius: 15px !important;
  background-color: #9c92a5 !important;
  top: 50% !important;
  transform: translate(-1px, -50%) !important;
}
.rs-slider-progress-bar {
  background-color: #6750a4 !important;
  border-radius: 3px 0 0 3px !important;
  height: 6px !important;
  position: absolute !important;
}
.css-1t2bqnt {
  background-color: #6750a4 !important;
  border-radius: 3px 0 0 3px !important;
  height: 6px !important;
  position: absolute !important;
  border: none;
}
.css-b04pc9 {
  background-color: #6750a4 !important;
  border-radius: 3px 0 0 3px !important;
  height: 6px !important;
  position: absolute !important;
}
.css-17lmo96 {
  display: none !important;
}
.rs-slider-graduator > ol > li.rs-slider-pass:before {
  display: none !important;
}
.rs-slider-handle {
  outline: none !important;
  position: absolute !important;
  top: -66% !important;
}

.css-eg0mwd-MuiSlider-thumb {
  background-color: #6750a4 !important;
  /* background-color: var(--rs-slider-thumb-bg); */
  border: 2px solid #6750a4 !important;
  border-radius: 15px !important;
  content: "" !important;
  cursor: pointer !important;
  height: 15px !important;
  margin-left: 1px !important;
  position: absolute !important;
  -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out !important;
  transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out !important;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out !important;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out !important;
  width: 7px !important;
}

.css-1gv0vcd-MuiSlider-track {
  background-color: #6750a4 !important;
  border-radius: 3px 0 0 3px !important;
  height: 6px !important;
  position: absolute !important;
  border: 1px solid #6750a4 !important;
}

.css-14pt78w-MuiSlider-rail {
  background-color: #e7e0ec !important;
  height: 6px !important;
  border-radius: 3px 0 0 3px !important;
}
.css-1tfve6q-MuiSlider-mark {
  position: absolute !important;
  width: 5px !important;
  height: 5px !important;
  border-radius: 4px !important;
  background-color: #cac4d0 !important;
  top: 50%;
  -webkit-transform: translate(-1px, -50%);
  -moz-transform: translate(-1px, -50%);
  -ms-transform: translate(-1px, -50%);
  transform: translate(-1px, -50%);
}
.css-3besu {
  display: none !important;
}
.css-6cwnna-MuiSlider-mark {
  display: none;
}
.css-1eoe787-MuiSlider-markLabel {
  font-weight: 600 !important;
  color: #838186 !important;
}
.css-nnid7-MuiSlider-valueLabel {
  display: none !important;
}

.purchase-order-arrow {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #d2d0d6;
  border: 1px solid #d2d0d6;
  transform: rotate(45deg);
  top: -8px;
  left: 25px;
  border-right: none;
  border-bottom: none;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 18px !important;
  color: #1d192b !important;
}

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 30px;
  /* margin: 20px 0; */
  /* box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%); */
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

.teepee-divider {
  border-top: 4px solid #ece5f2;
  margin: 5px 0px;
  border-radius: 4px;
}

.question-iteration {
  color: #403151;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.1px;
}
.question-text {
  color: #403151;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 127.273% */
  letter-spacing: 0.15px;
}
.option-buttons {
  border-radius: 8px;
  background: #e1dae3;
  padding: 15px 32px;
  width: 100%;
  margin-top: 2%;
  color: #252744;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24%; /* 3.84px */
  letter-spacing: 0.15px;
  text-align: start;
}
.disabled-submit-button {
  background: var(--m-3-state-layers-light-on-surface-opacity-012, rgba(29, 27, 32, 0.12));
  color: var(--M3-sys-light-on-surface, #1d1b20);
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding: 10px 24px;
  color: var(--M3-sys-light-on-surface, #1d1b20);
  text-align: center;

  /* M3/label/large */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  opacity: 0.38;
}
.enabled-submit-button {
  border-radius: 4px;
  background: #7e418b;
  padding: 10px 24px;
  color: var(--M3-sys-light-on-primary, #fff);
  text-align: center;

  /* M3/label/large */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.instruction-text {
  color: rgba(64, 49, 81, 0.84);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.score-text {
  color: #7e418b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44%; /* 15.84px */
}
.score-sub-text {
  color: #7e418b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24%; /* 3.84px */
}
.quiz-completed-text {
  color: #252744;
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.quiz-completed-sub-text {
  color: #252744;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24%; /* 3.84px */
  letter-spacing: 0.15px;
}
.feedback-text {
  color: #252744;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24%; /* 3.84px */
  letter-spacing: 0.15px;
}
.accordian-header-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.016px;
}

.label-bar {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #674fa4;
  border: solid 1px #674fa4;
  height: 5px;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.create_quiz_label_new {
  font-size: 1rem;
  font-weight: 600;
}
.custom-purple-btn {
  @apply bg-[#6750A4] hover:bg-[#6750A4] text-white  rounded-lg;
}
/* Custom scrollbar for WebKit-based browsers (Chrome, Safari, Edge) */
.scrollable-content::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

.scrollable-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Color of the scrollbar thumb */
  border-radius: 999px; /* Fully rounded corners */
  border: 2px solid #f1f1f1; /* Padding around the thumb to match the track */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #c1c1c1; /* Darker color on hover */
}

/* Custom scrollbar for Firefox */
.scrollable-content {
  scrollbar-width: thin; /* Slim scrollbar width in Firefox */
  scrollbar-color: #c1c1c1 #f1f1f1; /* Thumb and track color */
}

/* Styling the container for a better scroll experience */
.scrollable-content {
  overflow-y: auto; /* Enable vertical scrolling */
}
.GzzYU {
  display: none !important;
}

.feedback-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.feedback-table th,
.feedback-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.feedback-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.feedback-table code {
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
}
.feedback-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@keyframes moveUpAndVanish {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    display: none;
    transform: translateY(-30px);
  }
}

.move-up-vanish {
  animation: moveUpAndVanish 1s forwards;
}
.text-stroke {
  -webkit-text-stroke: 4px #0d47a1;
}
/* Basic MUI-like styling */
/* Ensure bullets are displayed for all unordered lists */
ul {
  list-style-type: disc; /* Ensures bullets are displayed */
  margin: 0; /* Adjusts spacing */
  padding-left: 20px; /* Adds spacing for indentation */
}

/* Optional: Handle nested lists */
ul ul {
  list-style-type: circle; /* Changes bullet style for nested lists */
  padding-left: 20px; /* Ensures proper alignment */
}

/* Ensure bullets are consistent across different list elements */
li {
  margin-bottom: 5px; /* Adds spacing between list items */
}

/* Optional: Add specific rules for ordered lists */
ol {
  list-style-type: decimal; /* Numbers for ordered lists */
  margin: 0;
  padding-left: 20px;
}
/* Custom style for the close button */
.swal2-close-button {
  color: #6750a4; /* Change close button color */
  font-size: 50px;
  font-weight: bold;
  border: none;
  background: transparent;
  cursor: pointer;
}
